import { render, staticRenderFns } from "./PoliceInfo.vue?vue&type=template&id=1ce09ef7&scoped=true&"
import script from "./PoliceInfo.vue?vue&type=script&lang=js&"
export * from "./PoliceInfo.vue?vue&type=script&lang=js&"
import style0 from "./PoliceInfo.vue?vue&type=style&index=0&id=1ce09ef7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce09ef7",
  null
  
)

export default component.exports